import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { Label, Paragraph, Text, Toggle } from 'shared/components/atoms'
import { ListingInfo } from 'job-listing/domains'

interface VisiblePayRateProps {
  listing: ListingInfo
  watch: UseFormWatch<Record<string, boolean>>
  setValue: UseFormSetValue<Record<string, string>>
}

export const VisiblePayRate = ({ listing, watch, setValue }: VisiblePayRateProps) => {
  return (
    <div className="flex gap-6 py-2">
      <Toggle
        name="hiringCostsVisible"
        setValue={setValue}
        checked={watch('hiringCostsVisible')}
        showCheck
        ariaLabelledBy="hiringCostsVisibleLabel"
      />
      <Label htmlFor="hiringCostsVisible">
        <Text
          size="text-sm"
          weight="font-medium"
          className="text-neutral-darkest"
          id="hiringCostsVisibleLabel"
        >
          Visible pay rate
        </Text>
        <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark mt-1">
          {listing.company.name} members can see hiring costs for candidates on this job listing.
        </Paragraph>
      </Label>
    </div>
  )
}
