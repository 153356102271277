import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { Label, Paragraph, Text, Toggle } from 'shared/components/atoms'
import { ListingInfo } from 'job-listing/domains'

interface EnableOfferMakingProps {
  listing: ListingInfo
  watch: UseFormWatch<Record<string, boolean>>
  setValue: UseFormSetValue<Record<string, string>>
}

const FT_HIRE_BUTTON_ALWAYS_VISIBLE = process.env.REACT_APP_FT_HIRE_BUTTON_ALWAYS_VISIBLE === 'true'

export const EnableOfferMaking = ({ listing, watch, setValue }: EnableOfferMakingProps) => {
  if (FT_HIRE_BUTTON_ALWAYS_VISIBLE) return null

  return (
    <div className="flex gap-6 py-2">
      <Toggle
        name="jobOffersEnabled"
        setValue={setValue}
        checked={watch('jobOffersEnabled')}
        showCheck
        ariaLabelledBy="jobOffersEnabledLabel"
      />
      <Label htmlFor="jobOffersEnabled">
        <Text
          size="text-sm"
          weight="font-medium"
          className="text-neutral-darkest"
          id="jobOffersEnabledLabel"
        >
          Enable offer making
        </Text>
        <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark mt-1">
          The {listing.company.name} members can make offer for any candidate linked to this job
          listing. Consider reviewing fees before doing so.
        </Paragraph>
      </Label>
    </div>
  )
}
