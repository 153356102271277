import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { Label, Paragraph, Text, Toggle } from 'shared/components/atoms'

interface HourlyRateProps {
  watch: UseFormWatch<Record<string, boolean>>
  setValue: UseFormSetValue<Record<string, string>>
}

export const HourlyRate = ({ watch, setValue }: HourlyRateProps) => {
  return (
    <div className="flex gap-6 py-2">
      <Toggle
        name="hourlyRatesEnabled"
        setValue={setValue}
        checked={watch('hourlyRatesEnabled')}
        showCheck
        ariaLabelledBy="hourlyRatesEnabledLabel"
      />
      <Label htmlFor="hourlyRatesEnabled">
        <Text
          size="text-sm"
          weight="font-medium"
          className="text-neutral-darkest"
          id="hourlyRatesEnabledLabel"
        >
          Change to Hourly Rates
        </Text>
        <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark mt-1">
          Display hourly rates and hide monthly rates on candidate profiles associated with this job
          listing.
        </Paragraph>
      </Label>
    </div>
  )
}
