import { cn } from 'modules/shared'
import * as Switch from '@radix-ui/react-switch'

import { ReactComponent as CheckIcon } from 'icons/check.svg'
import { UseFormSetValue } from 'react-hook-form'

interface ToggleProps {
  name: string
  checked?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  showCheck?: boolean
  disabled?: boolean
  ariaLabelledBy?: string
}

export const Toggle = ({
  checked = true,
  setValue,
  name,
  showCheck,
  disabled,
  ariaLabelledBy,
}: ToggleProps) => {
  const handleCheckChange = (checked: boolean) => {
    setValue(name, checked, { shouldTouch: true, shouldDirty: true })
  }

  return (
    <Switch.Root
      id={name}
      name={name}
      checked={checked}
      onCheckedChange={handleCheckChange}
      className={cn('w-9 h-5 rounded-full inline-block shrink-0', {
        'bg-neutral-light': (!checked && !disabled) || (checked && disabled),
        'bg-neutral-darker': checked && !disabled,
        'bg-neutral-lightest': !checked && disabled,
      })}
      disabled={disabled}
      aria-labelledby={ariaLabelledBy}
    >
      <Switch.Thumb
        className={cn(
          'flex items-center justify-center w-4 h-4 bg-white rounded-full',
          'transition duration-100 ',
          {
            'translate-x-[2px]': !checked,
            'translate-x-[18px]': checked,
          },
        )}
      >
        {checked && Boolean(showCheck) && (
          <CheckIcon className="stroke-neutral-darker w-[12px] h-[12px]" />
        )}
      </Switch.Thumb>
    </Switch.Root>
  )
}
