import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { Label, Toggle, Text, Paragraph } from 'shared/components/atoms'
import { AccountClaim, useAuth } from 'shared/hooks'

interface TalentReviewToggleProps {
  watch: UseFormWatch<Record<string, boolean>>
  setValue: UseFormSetValue<Record<string, string>>
}

export const TalentReviewToggle = ({ watch, setValue }: TalentReviewToggleProps) => {
  const {
    user: { claims },
  } = useAuth()

  if (!claims.includes(AccountClaim.ManageJobListingSettings)) return null

  return (
    <div className="flex gap-6 py-2">
      <Toggle
        name="isTalentReview"
        setValue={setValue}
        checked={watch('isTalentReview')}
        showCheck
        ariaLabelledBy="isTalentReviewLabel"
      />
      <Label htmlFor="isTalentReview">
        <Text
          size="text-sm"
          weight="font-medium"
          className="text-neutral-darkest"
          id="isTalentReviewLabel"
        >
          Enable Talent Review mode
        </Text>
        <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark mt-1">
          Talent Review listings have custom flows in the app such as skip e-mail sending for
          candidates.
        </Paragraph>
      </Label>
    </div>
  )
}
